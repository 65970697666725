




























import { Component, Prop, Vue } from 'vue-property-decorator';
import QrCodeDialog from '@/components/QRCodeDialog.vue';

@Component({
  components: {
    QrCodeDialog,
  },
})
export default class ScanQrCodeRow extends Vue {
  @Prop({ required: true, type: Boolean })
  isDrawerCollapsed!: boolean;

  public isScanningQRCode: boolean = false;

  get buttonSize(): number {
    return this.isDrawerCollapsed ? 60 : 90;
  }

  get iconProps() {
    if (!this.isDrawerCollapsed) return { 'x-large': true };

    return null;
  }
}
