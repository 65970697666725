
































































import {
  Prop, Component, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CompanyStoreGetters from '@/constants/store/modules/company/getters';
import Company from '@/typings/backend/jsonApiTranslations/company';
import CompanyStoreMutations from '@/constants/store/modules/company/mutations';

const companyStore = namespace('company');

@Component
export default class ChooseCompany extends Vue {
  @companyStore.Getter(CompanyStoreGetters.ALL_COMPANIES) companies!: Company[];

  @companyStore.Mutation [CompanyStoreMutations.SET_ACTIVE_COMPANY]: Function;

  @Prop({ required: true, type: Boolean })
  value!: boolean;

  public filterCompanyName: string = '';

  get filteredCompanies(): Company[] {
    if (this.filterCompanyName === '') {
      return this.companies;
    }

    return this.companies
      .filter(
        (company: Company) => company.name
          .toLowerCase()
          .includes(this.filterCompanyName.toLowerCase()),
      );
  }

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(value: boolean) {
    this.$emit('input', value);
  }

  public handleActiveCompanyClick(company: Company): void {
    this[CompanyStoreMutations.SET_ACTIVE_COMPANY](company);
    this.$router.push({ name: 'companyDashboard', params: { companyId: company.id } });
    this.isVisible = false;
  }
}
