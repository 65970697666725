




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UsersStoreGetters from '@/constants/store/modules/users/getters';
import User from '@/typings/backend/jsonApiTranslations/user';
import GymStoreGetters from '../../constants/store/modules/gyms/getters';
import Gym from '../../typings/backend/jsonApiTranslations/gym';

const gymStoreModule = namespace('gyms');
const userStoreModule = namespace('users');

@Component({})
export default class NameRow extends Vue {
  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym | null;

  @userStoreModule.Getter(UsersStoreGetters.GET_ACTIVE_USER) activeUser!: User;

  @Prop({ required: true, type: Boolean })
  isDrawerCollapsed!: boolean;

  get nameStyles() {
    return {
      width: '100%',
      transform: 'translateX(15px)',
      display: this.isDrawerCollapsed ? 'none' : 'block',
    };
  }
}
