


















































import { Component, Vue, Watch } from 'vue-property-decorator';
import AttendeeStoreMutations from '@/constants/store/modules/attendees/mutations';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import debounce from 'lodash.debounce';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AttendeeApiService from '@/services/api/AttendeeApiService';
import SearchAttendee from '@/components/attendee/SearchAttendee.vue';
import { namespace } from 'vuex-class';

const attendeeStoreModule = namespace('attendees');
const gymStoreModule = namespace('gyms');

@Component({
  components: { SearchAttendee },
})
export default class SearchAttendeeRow extends Vue {
  @attendeeStoreModule.Mutation [AttendeeStoreMutations.SET_ACTIVE_ATTENDEE]: (
    attendee: Attendee | null
  ) => void;

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @Watch('$route.path')
  public watchRoutePath() {
    this.attendeeName = '';
    this.attendees = [];
  }

  public attendees: Attendee[] = [];

  public attendeeName = '';

  public getAttendeesDebounced: Function = debounce(this.getAttendees, 500);

  public loaders = {
    fetchingAttendees: false,
  }

  private async getAttendees() {
    if (this.attendeeName === '') {
      this.attendees = [];

      return;
    }

    const params: JsonApiConfiguration = {
      include: ['address.country', 'phoneNumbers', 'activityGroups', 'payingGroups', 'additional', 'plans'],
      filter: {
        fullTextSearch: encodeURIComponent(this.attendeeName),
        gym: this.activeGym.id,
      },
    };

    this.loaders.fetchingAttendees = true;
    const response = await AttendeeApiService.list(params);
    this.loaders.fetchingAttendees = false;
    this.attendees = response.data;
  }
}
