





































































import Vue from 'vue';
import Component from 'vue-class-component';
import ChooseCompany from '@/components/company/ChooseCompany.vue';
import CreateCompanyComponent from '@/components/company/createCompany/CreateCompany.vue';
import Company from '@/typings/nativeEntities/company/company';
import Relationships from '@/typings/jsonApi/configuration/relationships';
import { namespace } from 'vuex-class';
import CompanyStoreActions from '@/constants/store/modules/company/actions';
import { AxiosResponse } from 'axios';
import CompanyStoreGetters from '@/constants/store/modules/company/getters';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import UserStoreMutations from '@/constants/store/modules/users/mutations';
import UsersStoreGetters from '@/constants/store/modules/users/getters';
import User from '@/typings/backend/jsonApiTranslations/user';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import LogoRow from '@/components/navigation/LogoRow.vue';
import ProfilePictureRow from '@/components/navigation/ProfilePictureRow.vue';
import NameRow from '@/components/navigation/NameRow.vue';
import RoutesRow from '@/components/navigation/RoutesRow.vue';
import ScanQrCodeRow from '@/components/navigation/ScanQrCodeRow.vue';
import SearchAttendeeRow from '@/components/navigation/SearchAttendeeRow.vue';

const companyStoreModule = namespace('company');
const gymStoreModule = namespace('gyms');
const userStoreModule = namespace('users');
const attendeeStoreModule = namespace('attendees');

@Component({
  components: {
    SearchAttendeeRow,
    ScanQrCodeRow,
    RoutesRow,
    NameRow,
    ProfilePictureRow,
    LogoRow,
    ChooseCompany,
    'create-company': CreateCompanyComponent,
  },
})
export default class NavigationBarLeft extends Vue {
  @companyStoreModule.Action [CompanyStoreActions.POST_NEW_COMPANY]: Function;

  @companyStoreModule.Getter(CompanyStoreGetters.GET_ACTIVE_COMPANY) activeCompany!: Company;

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym | null;

  @userStoreModule.Mutation [UserStoreMutations.SET_TOKEN]: Function;

  @userStoreModule.Getter(UsersStoreGetters.GET_ACTIVE_USER) activeUser!: User;

  @attendeeStoreModule.Getter(
    AttendeeStoreGetters.ACTIVE_ATTENDEE,
  ) activeAttendee!: Attendee | null;

  public drawer: boolean = true;

  public isDrawerCollapsed: boolean = true;

  public isPickingActiveCompany: boolean = false;

  public isCreatingCompany: boolean = false;

  public isCreatingCompanyLoader: boolean = false;

  public isSearching: boolean = false;

  get showMobileButtonOpenDrawer(): boolean {
    if (this.$vuetify.breakpoint.mobile) return true;

    return !this.isDrawerVisible;
  }

  get isDrawerVisible(): boolean {
    if (this.$vuetify.breakpoint.smAndDown) {
      return this.drawer;
    }

    return this.$vuetify.breakpoint.smAndUp;
  }

  public created(): void {
    if (this.$vuetify.breakpoint.mobile) {
      this.isDrawerCollapsed = false;
      this.drawer = false;
    }
  }

  public handleCompanyCreate({ company }: { company: Company }): void {
    const relationships: Relationships = {
      owners: {
        data: [{
          type: 'users',
          id: '2',
        }],
      },
    };

    this[CompanyStoreActions.POST_NEW_COMPANY]({ data: company, relationships })
      .then((response: AxiosResponse) => {
        this.isCreatingCompany = false;
        this.$toasted.show('Company successfully created');
      });
  }

  public logoutUser(): void {
    this[UserStoreMutations.SET_TOKEN](null);
    this.$toasted.show('You have been logout successfully');
    this.$router.push({ name: 'login' });
  }
}
