


































import { Component, Prop, Vue } from 'vue-property-decorator';
import UserStoreMutations from '@/constants/store/modules/users/mutations';
import { namespace } from 'vuex-class';

const userStoreModule = namespace('users');

@Component({})
export default class ProfilePictureRow extends Vue {
  @userStoreModule.Mutation [UserStoreMutations.SET_TOKEN]: Function;

  @Prop({ required: true, type: Boolean })
  isDrawerCollapsed!: boolean;

  public readonly btnWidth = 40;

  get avatarStyles() {
    const translate = this.isDrawerCollapsed ? '0px' : `${this.btnWidth / 2}px`;

    return {
      transform: `translateX(${translate})`,
    };
  }

  public logoutUser(): void {
    this[UserStoreMutations.SET_TOKEN](null);
    this.$toasted.show('You have been logout successfully');
    this.$router.push({ name: 'login' });
  }
}
