


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LogoMenu extends Vue {
  @Prop({ required: true, type: Boolean })
  isDrawerCollapsed!: boolean;

  public onCloseClick(): void {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$emit('close');

      return;
    }

    this.$emit('update:isDrawerCollapsed', !this.isDrawerCollapsed);
  }
}
