













































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import GymStoreGetters from '../../constants/store/modules/gyms/getters';
import Gym from '../../typings/backend/jsonApiTranslations/gym';
import UsersStoreGetters from '../../constants/store/modules/users/getters';
import User from '../../typings/backend/jsonApiTranslations/user';

const gymStoreModule = namespace('gyms');
const userStoreModule = namespace('users');

@Component({})
export default class RoutesRow extends Vue {
  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym | null;

  @userStoreModule.Getter(UsersStoreGetters.GET_ACTIVE_USER) activeUser!: User;

  @Prop({ required: true, type: Boolean })
  isDrawerCollapsed!: boolean;

  public cItems = [
    { text: 'Attendees', routeName: 'usersList', icon: 'mdi-account-multiple-outline' },
    { text: 'Activity Groups', routeName: 'groupList', icon: 'mdi-account-group-outline' },
    { text: 'Calendar', routeName: 'groupsCalendar', icon: 'mdi-calendar-blank-outline' },
    { text: 'Plans', routeName: 'planList', icon: 'mdi-book-open-outline' },
    { text: 'Third Party Paying', routeName: 'thirdPartyPayingList', icon: 'mdi-currency-usd' },
    {
      text: 'Employees', routeName: 'staffMembersList', onlyOwnerCanSee: true, icon: 'mdi-account-circle-outline',
    },
  ]

  get itemsFiltered() {
    if (this.activeGym && this.activeGym.createdBy!.id === this.activeUser.id) {
      return this.cItems;
    }

    return this.cItems.filter(i => !i.onlyOwnerCanSee);
  }
}
