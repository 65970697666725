



































import { Prop, Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd';
import debounce from 'lodash.debounce';
import AttendeeApiService from '@/services/api/AttendeeApiService';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { Loaders } from '@/typings/loaders';
import AttendeeStoreMutations from '@/constants/store/modules/attendees/mutations';

const gymStoreModule = namespace('gyms');
const attendeeStoreModule = namespace('attendees');

@Component({
  components: {
    VueQrReader,
  },
})
export default class QRCodeDialog extends Vue {
  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @attendeeStoreModule.Mutation [AttendeeStoreMutations.SET_ACTIVE_ATTENDEE]: (
    attendee: Attendee | null
  ) => void;

  @Prop({ required: true })
  value!: boolean;

  public errorMessage: string = '';

  public codeArrivedDebounced: Function = debounce(this.handleCodeArrived, 200);

  public isQRCodeOpened: boolean = true;

  public loaders: Loaders = {
    fetchingAttendee: false,
  }

  get isVisible(): boolean {
    this.isQRCodeOpened = this.value;

    return this.value;
  }

  set isVisible(newValue: boolean) {
    this.$emit('input', newValue);

    if (!newValue) this.isQRCodeOpened = false;
  }

  public codeArrived(attendeeCode: any) {
    this.codeArrivedDebounced(attendeeCode);
  }

  public handleCodeArrived(attendeeCode: any): void {
    this.loaders.fetchingAttendee = true;

    const params: JsonApiConfiguration = {
      include: ['address.country', 'phoneNumbers'],
      filter: {
        gym: this.activeGym.id,
        code: attendeeCode.split('/').slice(-1)[0],
      },
    };

    AttendeeApiService
      .list(params)
      .then((response) => {
        if (response.data.length < 1) {
          this.errorMessage = 'This attendee was not found';

          return;
        }

        this[AttendeeStoreMutations.SET_ACTIVE_ATTENDEE](response.data[0]);
        this.isQRCodeOpened = false;
      })
      .finally(() => {
        this.loaders.fetchingAttendee = false;
      });
  }

  public errorCaptured(error: any) {
    switch (error.name) {
      case 'NotAllowedError':
        this.errorMessage = 'Camera permission denied.';
        break;
      case 'NotFoundError':
        this.errorMessage = 'There is no connected camera.';
        break;
      case 'NotSupportedError':
        this.errorMessage = 'Seems like this page is served in non-secure context.';
        break;
      case 'NotReadableError':
        this.errorMessage = 'Couldn\'t access your camera. Is it already in use?';
        break;
      case 'OverconstrainedError':
        this.errorMessage = 'Constraints don\'t match any installed camera.';
        break;
      default:
        this.errorMessage = `UNKNOWN ERROR: ${error.message}`;
    }
  }
}
